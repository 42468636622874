import { forwardRef } from 'react';

import { FlexBox } from '../../flex-box';
import { XCloseIcon } from '../../icon/icons';
import { Tag } from '../../tag/tag';

import { TriggerStyleProps, triggerStyles } from './dropdown-trigger.styles';
import { TriggerContent, TriggerContentProps } from './trigger-content';

type DropdownMenuTriggerProps = TriggerContentProps & {
    setTriggerDisabled: (value: boolean) => void;
    onRemoveTag: (value: string) => void;
} & TriggerStyleProps;

const DropdownMenuTrigger = forwardRef<HTMLButtonElement, DropdownMenuTriggerProps>(function DropdownMenuTrigger(
    {
        variant,
        selected,
        size,
        fullWidth,
        containTriggerLabel,
        label,
        internalItems,
        setTriggerDisabled,
        onRemoveTag,
        ...rest
    },
    ref,
) {
    const { base, tagsContainer } = triggerStyles({ size, fullWidth });

    if (variant === 'tags') {
        return (
            <div className={tagsContainer()} ref={ref as any} {...rest}>
                <FlexBox gap="sm" wrap>
                    {selected.map(value => (
                        <Tag variant="primary" key={value}>
                            {value}
                            <button
                                onClick={() => {
                                    setTriggerDisabled(false);
                                    onRemoveTag(value);
                                }}
                                onMouseEnter={() => {
                                    setTriggerDisabled(true);
                                }}
                                onMouseLeave={() => {
                                    setTriggerDisabled(false);
                                }}
                            >
                                <XCloseIcon className="w-3 h-3" />
                            </button>
                        </Tag>
                    ))}
                </FlexBox>
                <button>
                    <TriggerContent
                        variant="tags"
                        label={label}
                        selected={selected}
                        containTriggerLabel={containTriggerLabel}
                        internalItems={internalItems}
                    />
                </button>
            </div>
        );
    }

    return (
        <button className={base()} ref={ref as any} {...rest}>
            <TriggerContent
                label={label}
                selected={selected}
                containTriggerLabel={containTriggerLabel}
                internalItems={internalItems}
            />
        </button>
    );
});

export type { DropdownMenuTriggerProps };
export { DropdownMenuTrigger };
