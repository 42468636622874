import { useCallback, useMemo, useRef, useState } from 'react';

import type { MenuItem } from '../dropdown-menu.model';

type DropdownMenuProps = {
    items: MenuItem[];
    searchable?: boolean;
    clearable?: boolean;
};

/**
 * A dropdown menu in which each item behaves like a checkbox.
 */
const useDropdownSearch = ({ items, searchable, clearable }: DropdownMenuProps) => {
    const [searchValue, setSearchValue] = useState('');
    const firstItemRef = useRef<HTMLDivElement>(null);
    const searchRef = useRef<HTMLInputElement>(null);
    const clearRef = useRef<HTMLDivElement>(null);
    const selectAllRef = useRef<HTMLButtonElement>(null);

    const handleClearSearchValue = useCallback(() => searchable && setSearchValue(''), [searchable]);

    const filteredItems = useMemo(() => {
        return (
            searchable
                ? items.filter(Boolean).filter(item => {
                      const label = (item.textLabel ?? String(item.label)).toLowerCase();
                      const lowerCaseSearch = searchValue.toLowerCase();
                      const titleIncluded = label.includes(lowerCaseSearch);
                      const codeIncluded = item.value?.toLowerCase().includes(lowerCaseSearch);
                      return titleIncluded || codeIncluded;
                  })
                : items
        ) as MenuItem[];
    }, [items, searchValue, searchable]);

    const getItemProps = ({ isFirstItem, isClearItem }: { isFirstItem?: boolean; isClearItem?: boolean }) => {
        if (isClearItem) {
            return {
                ref: clearRef,
                onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => {
                    /** only focus search input if searchable and the user presses the up arrow */
                    if (e.key === 'ArrowUp' && searchRef?.current && searchable) {
                        e.stopPropagation();
                        e.preventDefault();
                        searchRef.current.focus();
                    }
                },
            };
        }
        if (isFirstItem) {
            return {
                ref: firstItemRef,
                onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => {
                    if (e.key === 'ArrowUp' && searchRef?.current && searchable) {
                        if (clearable) {
                            return e.defaultPrevented;
                        }
                        e.stopPropagation();
                        e.preventDefault();
                        searchRef.current.focus();
                    }
                    return e.defaultPrevented;
                },
            };
        }
        return {};
    };

    const getSearchInputProps = () => ({
        ref: searchRef,
        onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
            e.stopPropagation();
            if (e.key === 'ArrowDown' && firstItemRef?.current) {
                e.preventDefault();
                if (clearable && clearRef?.current) {
                    clearRef.current.focus();
                    return e.defaultPrevented;
                }
                firstItemRef.current.focus();
            }
            if (e.key === 'ArrowRight' && selectAllRef?.current) {
                e.preventDefault();
                selectAllRef.current.focus();
            }
            return e.defaultPrevented;
        },
        onKeyUp: (e: React.KeyboardEvent<HTMLInputElement>) => e.stopPropagation(),
    });

    const getSelectAllProps = () => {
        return {
            ref: selectAllRef,
            onKeyDown: (e: React.KeyboardEvent<HTMLButtonElement>) => {
                if (e.key === 'ArrowDown' && firstItemRef?.current) {
                    e.stopPropagation();
                    e.preventDefault();
                    if (clearable && clearRef?.current) {
                        clearRef.current.focus();
                        return e.defaultPrevented;
                    }
                    firstItemRef.current.focus();
                }
                if (e.key === 'ArrowLeft' && searchRef?.current) {
                    e.stopPropagation();
                    e.preventDefault();
                    searchRef.current.focus();
                }
                return e.defaultPrevented;
            },
        };
    };

    return {
        /** items */
        filteredItems,

        /** search value setters */
        setSearchValue,
        handleClearSearchValue,

        /** prop getters */
        getSearchInputProps,
        getItemProps,
        getSelectAllProps,
    };
};

export type { DropdownMenuProps };
export { useDropdownSearch };
