import { ChevronDownIcon, ChevronUpIcon, FlexBox, HotkeyHint } from '@blockworks/ui';

export const HotkeyHintBar = () => {
    return (
        <FlexBox gap="sm" alignItems="center" px={2} py={2} bgColor="muted" overflowX="auto">
            <HotkeyHint command="Enter" action="Select" />
            <HotkeyHint command="Esc" action="Close" />
            {/* chevron down and chevron up */}
            <HotkeyHint action="Navigate">
                <FlexBox gap="2xs" alignItems="center" flexWrap="nowrap">
                    <ChevronDownIcon size={0.75} color="base" />
                    <ChevronUpIcon size={0.75} color="base" />
                </FlexBox>
            </HotkeyHint>
            <HotkeyHint command="/as" action="Assets" />
            <HotkeyHint command="/re" action="Research" />
            <HotkeyHint command="/da" action="Dashboards" />
            <HotkeyHint command="/an" action="Analysts" />
            <HotkeyHint command="/pr" action="Proposals" />
            {/* <HotkeyHint command="/po" action="Podcasts" /> */}
            <HotkeyHint command="/ne" action="Newsletters" />
            <HotkeyHint command="/pa" action="Pages" />
        </FlexBox>
    );
};
