import { forwardRef, LegacyRef } from 'react';
import * as styles from '../icon.styles';
import type { IconProps } from '../icon.model';
const ArrowRightIcon = forwardRef(
    ({ title, titleId, color, borderRadius, ...props }: IconProps, ref?: LegacyRef<SVGSVGElement>) => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 16 17"
            width={props.width}
            height={props.height}
            role="img"
            aria-hidden="true"
            className={styles.icon({
                ...props,
                borderRadius,
                color,
            })}
            ref={ref}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                fill="currentColor"
                d="M8.471 3.076a.667.667 0 1 0-.942.943l3.528 3.528H3.333a.667.667 0 1 0 0 1.334h7.724L7.53 12.409a.667.667 0 1 0 .942.943l4.667-4.666a.667.667 0 0 0 0-.943z"
            />
        </svg>
    ),
);
ArrowRightIcon.displayName = 'ArrowRightIcon';
export default ArrowRightIcon;
