import { NewsHit } from '@blockworks/platform/api/algolia';
import { FlexBox, List, Text } from '@blockworks/ui/components';
import { DateFormat } from '@blockworks/ui/date-time';

import { SearchHitProps } from '../utils/search.types';

import { HitItemImage } from './item-components/hit-item-image';
import { ResultHitItemRow } from './item-components/result-hit-item-row';
import { TruncatedTitleText } from './item-components/truncated-title-text';

export const NewsItem = (props: SearchHitProps<NewsHit>) => {
    const { onSelect, ...hit } = props;

    return (
        <ResultHitItemRow id={hit.objectID} href={hit.link} newTab onClick={() => onSelect?.(hit)}>
            <List.Col size="content">
                <HitItemImage size={{ w: 40, h: 22.5 }} alt={hit.title} src={hit.imageUrl as string} />
            </List.Col>
            <List.Col size="fit" grow>
                <FlexBox h="full" alignItems="start" col>
                    <TruncatedTitleText title={hit.title} />
                    <Text size="xs" color="deselect" lines={1}>
                        <DateFormat date={hit.date!} />
                    </Text>
                </FlexBox>
            </List.Col>
            <List.Col size="content" shrink>
                <Text size="xs" color="deselect">
                    {`Blockworks`}
                </Text>
            </List.Col>{' '}
        </ResultHitItemRow>
    );
};
