import { useState } from 'react';

import { IconButton, List, Text, XCloseIcon } from '@blockworks/ui';
import { Bit } from '@blockworks/ui/bit';
import { DateFormat } from '@blockworks/ui/date-time';

import { RecentSearchItemType } from '../utils/search.types';

interface RecentSearchItemProps {
    item: RecentSearchItemType;
    onItemDelete: () => void;
    onItemSelect: (item: RecentSearchItemType) => void;
}

export const RecentSearchItem = ({ item, onItemDelete, onItemSelect }: RecentSearchItemProps) => {
    const [canDelete, setCanDelete] = useState(false);

    const handleItemDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();
        onItemDelete();
    };

    return (
        <Bit.div onMouseEnter={() => setCanDelete(true)} onMouseLeave={() => setCanDelete(false)}>
            <List.Row
                key={item.searchTerm}
                w="full"
                as="li"
                bgColor="base"
                py={1}
                justifyContent="between"
                rounded
                onClick={() => onItemSelect(item)}
                data-testid={`recent-search-${item.searchTerm}`}
            >
                <List.Col size="fit" grow>
                    <Text size="sm" lines={1}>
                        {item.searchTerm}
                    </Text>
                </List.Col>
                <List.Col size="content">
                    <DateFormat date={item.isoDate} />
                </List.Col>
                {canDelete && (
                    <List.Col size="content" shrink>
                        <IconButton
                            icon={XCloseIcon}
                            aria-label="Delete Search"
                            size="2xs"
                            variant="ghost"
                            onClick={handleItemDelete}
                        />
                    </List.Col>
                )}
            </List.Row>
        </Bit.div>
    );
};
