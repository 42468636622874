import { tVariants } from '../../style-system';
import { factoryStyleProps } from '../../style-system/factory/style-props';

const badgeStyles = tVariants(
    {
        base: 'rounded-full w-max inline-block border whitespace-nowrap bg-current-bg',
        variants: {
            size: {
                xs: 'py-0.25 px-1.5 text-xxs',
                sm: 'px-2 py-0.5 text-xs',
            },
            variant: {
                dashed: '[--color-current-bg:--badge-fill-alt] border-dashed text-[--badge-text] border-[--badge-outline]',
                outline: '[--color-current-bg:--badge-fill-alt] border-[--badge-outline] text-[--badge-text]',
                fill: '[--color-current-bg:--badge-fill] text-[--badge-text] border-current-bg',
            },
            intent: {
                primary:
                    '[--badge-text:--color-content-interactive] [--badge-outline:--color-primary-surface] [--badge-fill:--color-primary-surface] [--badge-fill-alt:--color-primary-surface]',
                neutral:
                    '[--badge-text:--color-content-muted] [--badge-outline:--color-surface-selected] [--badge-fill:--color-surface-muted] [--badge-fill-alt:--color-surface-depth]',
                warning:
                    '[--badge-text:--color-warning-content] [--badge-outline:--color-warning-surface] [--badge-fill:--color-warning-surface] [--badge-fill-alt:--color-warning-surface]',
                success:
                    '[--badge-text:--color-success-content] [--badge-outline:--color-success-surface] [--badge-fill:--color-success-surface] [--badge-fill-alt:--color-success-surface]',
                danger: '[--badge-text:--color-danger-content] [--badge-outline:--color-danger-surface] [--badge-fill:--color-danger-surface] [--badge-fill-alt:--color-danger-surface]',
                info: '[--badge-text:--color-info-content] [--badge-outline:--color-info-surface] [--badge-fill:--color-info-surface] [--badge-fill-alt:--color-info-surface]',
            },
            ...factoryStyleProps.size,
            ...factoryStyleProps.typography,
            ...factoryStyleProps.color,
            display: factoryStyleProps.layout.display,
            ...factoryStyleProps.__atoms.layout.flex,
            ...factoryStyleProps.space,
        },
    },
    { responsiveVariants: true },
);

export { badgeStyles };
