import React, { useRef, useState } from 'react';
import algoliasearch from 'algoliasearch/lite';

import { InstantSearch } from '@blockworks/platform/libs/react-instantsearch';
import { ResearchIndexes } from '@blockworks/platform/services/algolia';
import { useTrack } from '@blockworks/platform/services/analytics';
import { Bit } from '@blockworks/ui/bit';
import { Modal, ScrollArea } from '@blockworks/ui/components';

import { AnalyticsEvent } from '@/modules/utils/enums/events-tracking';
import ResearchConfig from '@/research-config';

import { RecentSearchSection } from './recent-search-section/recent-search-section';
import { DesktopSearchInput } from './search-inputs/desktop-search-input';
import { MobileSearchInput } from './search-inputs/mobile-search-input';
import { ModalSearchInput } from './search-inputs/modal-search-input';
import { SearchContext } from './utils/hooks/use-search-context';
import { useSearchHitsNavigation } from './utils/hooks/use-search-hits-navigation';
import { SEARCH_CATEGORIES } from './utils/search.constants';
import { SearchCategoryValues } from './utils/search.enums';
import { RecentSearchItemType, SearchCategoryType } from './utils/search.types';
import { getCategoryByValue } from './utils/search.utils';
import { CategoriesSection } from './categories-section';
import { HotkeyHintBar } from './hotkey-hint-bar';
import { ResultHits } from './result-hits';
import { ResultSection } from './result-section';

const searchClient = algoliasearch(ResearchConfig.ALGOLIA_APP_ID, ResearchConfig.ALGOLIA_KEY);

interface SearchProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export const Search = ({ open, setOpen }: SearchProps) => {
    const track = useTrack();
    const resultsRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [inputValue, setInputValue] = useState('');
    const [selectedCategory, setSelectedCategory] = useState<SearchCategoryType | undefined>(undefined);
    const [focusedHitId, setFocusedHitId] = useState<string | undefined>(undefined);
    const [focusedIndex, setFocusedIndex] = useState<number | undefined>(undefined);
    const { handleInputKeyDown } = useSearchHitsNavigation({
        query: inputValue,
        focusedIndex,
        onFocusedIndexChange: index => setFocusedIndex(index),
        onFocusedHitIdChange: id => setFocusedHitId(id),
    });
    const isViewingAll = typeof selectedCategory === 'undefined';

    const selectableSections = (selectedCategory ? [selectedCategory] : SEARCH_CATEGORIES).filter(c => !!c.selectable);

    const onCatgeoryChange = (category: SearchCategoryType | undefined) => {
        setSelectedCategory(category);
        inputRef.current?.focus();
    };

    const handleInputClick = () => {
        setOpen(true);
        track(AnalyticsEvent.clickedSearchBar, { source: 'global-search' });
    };

    const handleHitSelect = () => {
        handleSetOpen(false);
        setInputValue('');
    };

    const handleSetOpen = (open: boolean) => {
        setOpen(open);
        if (!open) {
            setSelectedCategory(undefined);
            setInputValue('');
        }
    };

    const handleRecentSearchItemSelect = (item: RecentSearchItemType) => {
        setInputValue(item.searchTerm);
    };

    return (
        <>
            <DesktopSearchInput open={open} handleInputClick={handleInputClick} />
            <MobileSearchInput handleInputClick={handleInputClick} />

            <Modal open={open} onOpenChange={handleSetOpen} shadow="lg" borderRadius="xl">
                <Bit.div
                    className="bg-surface-depth"
                    w={{ initial: '90vw', sm: '80vw', lg: 900, xl: 1000 }}
                    onKeyDown={handleInputKeyDown}
                >
                    <SearchContext.Provider value={{ focusedHitId }}>
                        <InstantSearch searchClient={searchClient} indexName={ResearchIndexes.BWRSearch}>
                            <ModalSearchInput
                                resultsRef={resultsRef}
                                inputValue={inputValue}
                                inputRef={inputRef}
                                selectedCategory={selectedCategory}
                                onCategoryChange={onCatgeoryChange}
                                onInputChange={setInputValue}
                                resetFocusedHitIndex={() => setFocusedIndex(undefined)}
                            >
                                {!isViewingAll && (
                                    <ModalSearchInput.BackIcon onClick={() => onCatgeoryChange(undefined)} />
                                )}
                            </ModalSearchInput>
                            <Bit.div minH={{ initial: '70vh', md: '80vh' }} bgColor="base">
                                <ScrollArea h={'80vh'} px={2} pr={4} ref={resultsRef}>
                                    <CategoriesSection selected={selectedCategory} onSelect={onCatgeoryChange} />
                                    <RecentSearchSection
                                        selectedCategory={selectedCategory}
                                        onItemSelect={handleRecentSearchItemSelect}
                                        onSelect={() =>
                                            onCatgeoryChange(getCategoryByValue(SearchCategoryValues.RECENT_SEARCHES))
                                        }
                                    />
                                    {selectableSections?.map(section => (
                                        <ResultSection key={section.title}>
                                            {isViewingAll && (
                                                <ResultSection.Header
                                                    title={section.title}
                                                    onSelect={() => onCatgeoryChange(getCategoryByValue(section.value))}
                                                />
                                            )}
                                            <ResultHits
                                                hitComponent={section.hitComponent!}
                                                limit={isViewingAll ? 5 : 25}
                                                onHitSelect={handleHitSelect}
                                                sectionTitle={section.title}
                                                filters={section.filters!}
                                            >
                                                {!isViewingAll && <ResultHits.ShowMoreButton />}
                                            </ResultHits>
                                        </ResultSection>
                                    ))}
                                    {/* Add a spacer for bottom-padding */}
                                    <Bit.div h={40} />
                                </ScrollArea>
                            </Bit.div>
                            <HotkeyHintBar />
                        </InstantSearch>
                    </SearchContext.Provider>
                </Bit.div>
            </Modal>
        </>
    );
};
