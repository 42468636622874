import { FlexBox, HotkeyHint, Input, MagnifyingGlassIcon } from '@blockworks/ui';
import { Bit } from '@blockworks/ui/bit';

interface DesktopSearchInputProps {
    open: boolean;
    handleInputClick: () => void;
}

export const DesktopSearchInput = ({ open, handleInputClick }: DesktopSearchInputProps) => (
    <Bit.div onClick={handleInputClick} display={{ initial: 'hidden', md: 'block' }} w="full">
        <FlexBox alignItems="center" pl={3} pos="relative" flexGrow={1} w="full">
            <FlexBox pos="absolute" alignItems="center" w="full" gap="sm">
                <MagnifyingGlassIcon className="text-content-deselected h-6 w-6" />
                <Bit.div className="text-content-deselected text-sm" id="search-text" truncate>
                    Search for assets, articles, and more...
                </Bit.div>
                <HotkeyHint command={`⌘ K`} />
            </FlexBox>
            <Input
                size="md"
                aria-label="Search"
                aria-describedby="search-text"
                w="full"
                id="global-search"
                variant="transparent"
                noOutline
                name="search-field"
                disabled={open}
                type="search"
            />
        </FlexBox>
    </Bit.div>
);
