import { tVariants, TVStylePropsWithoutClassName } from '../../../style-system';

const triggerStyles = tVariants({
    base: [
        'group/multi py-1 px-2 data-highlighted:outline-0 flex gap-1 outline-none items-center',
        'text-content-muted h-[24px] bg-surface-muted border border-surface-divider rounded-full',
        'hover:border-surface-interact data-open/select-trigger:border-surface-interact',
        'focus-visible:outline-none focus-visible:border-surface-interact',
    ],
    slots: {
        tagsContainer: [
            'flex p-2 justify-between items-center min-h-[42px] border border-surface-divider rounded bg-surface px-2 cursor-pointer',
            'hover:border-surface-interact data-open/select-trigger:border-surface-interact',
            'focus-visible:outline-none focus-visible:border-surface-interact',
        ],
    },
    variants: {
        size: {
            sm: 'text-xs',
            md: 'text-sm',
        },
        fullWidth: {
            true: 'w-full justify-between',
        },
    },
});

const iconStyles = tVariants({
    base: 'leading w-4 h-4 group-data-open/multi:rotate-180 transition-transform',
});

type TriggerStyleProps = TVStylePropsWithoutClassName<typeof triggerStyles>;

export type { TriggerStyleProps };
export { iconStyles, triggerStyles };
