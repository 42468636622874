import { PropsWithChildren } from 'react';

import { FlexBox } from '../flex-box';
import { Text } from '../text';

interface HotkeyHintProps extends PropsWithChildren {
    command?: string;
    action?: string;
    children?: React.ReactNode;
}

/**
 * @param key Hotkey Key or combination of keys as string
 * @param command Command or action name as string
 * @param children Hotkey content for custom rendering
 */
export const HotkeyHint = ({ command, action, children }: HotkeyHintProps) => {
    return (
        <FlexBox
            display="inline-flex"
            role="tooltip"
            aria-label={`Hotkey ${command}${action ? ` for ${action}` : ''}`}
            gap="xs"
            alignItems="center"
            bgColor="raised"
            border={1}
            borderColor="selected"
            borderRadius="lg"
            px={2}
            py={1}
            flexWrap="nowrap"
            flexShrink={0}
        >
            <HotKeyContent command={command}>{children}</HotKeyContent>
            {action && (
                <Text size="xxs" color="deselect">
                    {action}
                </Text>
            )}
        </FlexBox>
    );
};

const HotKeyContent = ({ command, children }: HotkeyHintProps) => {
    if (children) {
        return children;
    }
    return (
        <Text size="xxs" color="base" font="display">
            {command}
        </Text>
    );
};
