import React, { useEffect, useState } from 'react';
import { FilterStatus } from '@knocklabs/react';
import { useRouter } from 'next/router';

import { useBoolean } from '@blockworks/platform/hooks';
import { Bit } from '@blockworks/ui/bit';
import { Button, FlexBox, Link, Popover, Tabs, Text } from '@blockworks/ui/components';
import { BellIcon } from '@blockworks/ui/icon';

import { NotificationPopoverList } from '@/components/notifications/notification-popover/notification-popover-list';
import { useKnock } from '@/hooks/use-knock';
import { AppRoutes } from '@/services/auth/auth.routes';

const NotificationBell = () => {
    const router = useRouter();
    const [filter, setFilter] = useState<FilterStatus>(FilterStatus.Unread);
    const { value: isOpen, setValue: setIsOpen } = useBoolean(false);
    const { knockFeed, knockFeedStore } = useKnock();
    const { metadata } = knockFeedStore;
    const { unread_count: unreadCount } = metadata ?? {};
    const showMoreUrl = `${AppRoutes.NOTIFICATIONS.pathname}?status=${filter}`;

    // Knock's `markAsRead` method doesn't update the store (though it should)
    // so we need to manually update the store when the route changes
    useEffect(() => {
        if (!knockFeed || !knockFeedStore || !router.events) return;

        const handleReadEvent = async () => {
            try {
                const res = await knockFeed.fetch();
                if (!res?.data?.meta?.total_count) return;
                knockFeedStore.setMetadata(res.data.meta);
            } catch (error) {
                console.error('Failed to fetch notification metadata:', error);
            }
        };

        router.events.on('routeChangeComplete', handleReadEvent);

        return () => {
            router.events.off('routeChangeComplete', handleReadEvent);
        };
    }, [knockFeed, knockFeedStore, router.events]);

    const handleSetIsOpen = (open: boolean) => {
        setIsOpen(open);
        setFilter(FilterStatus.Unread);
    };

    return (
        <Popover
            open={isOpen}
            onOpenChange={handleSetIsOpen}
            trigger={
                <Button active={isOpen} leadingIcon={BellIcon} variant="ghost" size={{ initial: 'xxs', sm: 'xs' }}>
                    {unreadCount ?? 0}
                </Button>
            }
            contentProps={{
                align: 'end',
            }}
        >
            <FlexBox w={400}>
                <FlexBox col w="full" justifyContent="center">
                    <Tabs
                        label="Notifications"
                        onValueChange={setFilter}
                        tabs={[
                            {
                                id: FilterStatus.Unread,
                                title: 'Unread',
                                counter: unreadCount,
                                component: () => (
                                    <NotificationPopoverList setIsOpen={setIsOpen} filterStatus={FilterStatus.Unread} />
                                ),
                            },
                            {
                                id: FilterStatus.All,
                                title: 'All',
                                component: () => (
                                    <NotificationPopoverList setIsOpen={setIsOpen} filterStatus={FilterStatus.All} />
                                ),
                            },
                        ]}
                    />
                    <FlexBox center bgColor="contrast" w="full" col gap="none">
                        <Link
                            justifyContent="center"
                            size="md"
                            w="full"
                            href={showMoreUrl}
                            onClick={() => handleSetIsOpen(false)}
                            classNames={{
                                base: 'hover:bg-surface-highlight border-t border-t-muted rounded-b-xl',
                            }}
                        >
                            <Bit.div p={3}>
                                <Text weight="semibold" size="xs">
                                    Show more
                                </Text>
                            </Bit.div>
                        </Link>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </Popover>
    );
};

export default NotificationBell;
