import { useState } from 'react';

import { Button, FlexBox, List, Text } from '@blockworks/ui';

import { ResultsSectionHeader } from '../result-section-header';
import { SearchCategoryValues } from '../utils/search.enums';
import { RecentSearchItemType, SearchCategoryType } from '../utils/search.types';
import { clearRecentSearches, deleteRecentSearch, getRecentSearches } from '../utils/search.utils';

import { RecentSearchItem } from './recent-search-item';

interface RecentSearchSectionProps {
    selectedCategory: SearchCategoryType | undefined;
    onItemSelect: (item: RecentSearchItemType) => void;
    onSelect: () => void;
}

export const RecentSearchSection = ({ selectedCategory, onItemSelect, onSelect }: RecentSearchSectionProps) => {
    const [searches, setSearches] = useState(getRecentSearches());
    const isViewingRecentSearches = selectedCategory?.value === SearchCategoryValues.RECENT_SEARCHES;
    const visibleRows = isViewingRecentSearches ? searches : searches.slice(0, 5);
    const isViewingAll = typeof selectedCategory === 'undefined';
    const hasSearches = Boolean(searches.length);

    const handleActionSelect = () => {
        // Clear recent searches if we're inside the recent searches section
        if (selectedCategory?.value === SearchCategoryValues.RECENT_SEARCHES) {
            clearRecentSearches();
            setSearches([]);
        }
        onSelect();
    };

    const handleItemDelete = (item: RecentSearchItemType) => {
        deleteRecentSearch(item.searchTerm);
        setSearches(getRecentSearches());
    };

    // if the view is set to a different category, or if there are no recent search values, hide this section
    if ((isViewingAll && !hasSearches) || (!isViewingRecentSearches && !isViewingAll)) {
        return null;
    }

    if (isViewingRecentSearches && !hasSearches) {
        return (
            <FlexBox col>
                <ResultsSectionHeader title="Recent Searches" />
                <Text size="xs" px={2} py={4} color="muted">{`Your recent searches will appear here.`}</Text>
            </FlexBox>
        );
    }

    return (
        <FlexBox col>
            <ResultsSectionHeader title="Recent Searches">
                <RecentSearchesActionButton selectedCategory={selectedCategory} onSelect={handleActionSelect} />
            </ResultsSectionHeader>
            <List variant="compact-transparent" as="ul" name="recent-searches" data-testid={'recent-searches'}>
                {visibleRows?.map(item => (
                    <RecentSearchItem
                        key={item.searchTerm}
                        item={item}
                        onItemSelect={onItemSelect}
                        onItemDelete={() => handleItemDelete(item)}
                    />
                ))}
            </List>
        </FlexBox>
    );
};

const RecentSearchesActionButton = ({
    selectedCategory,
    onSelect,
}: {
    selectedCategory: SearchCategoryType | undefined;
    onSelect: RecentSearchSectionProps['onSelect'];
}) => {
    if (selectedCategory?.value === SearchCategoryValues.RECENT_SEARCHES) {
        return (
            <Button size="xs" variant="ghost" onClick={onSelect}>
                {`Clear all`}
            </Button>
        );
    }
    return <ResultsSectionHeader.ExpandCategoryButton onSelect={onSelect} />;
};
