import { useRef, useState } from 'react';
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';

import { Avatar } from '../avatar';
import { Button } from '../button';
import { FlexBox } from '../flex-box';
import { CheckIcon } from '../icon/icons';
import { ScrollArea } from '../scroll-area/scroll-area';
import { Text } from '../text';

import type { MenuItem } from './dropdown-menu.model';
import { multiSelectStyles } from './dropdown-menu.styles';
import { DropdownMenuTrigger, type DropdownMenuTriggerProps } from './dropdown-trigger';
import { hasRawValues, useDropdownSearch, useDynamicTriggerWidth } from './utils';

type DropdownMenuProps = {
    items: string[] | MenuItem[];
    /**
     * Whether the options are searchable or not.
     * @default false
     */
    searchable?: boolean;
    /**
     * Passing `true` disables the ability to select multiple options.
     * @default false
     */
    disableMultipleSelections?: boolean;
    /**
     * Whether to recalculate and resize trigger width on the window `resize` event.
     * Passing `variant="tags"` will make this `true` - you can pass `false` to turn it off
     * @default false
     */
    dynamicTriggerWidth?: boolean;
    /** Callback for when an item is selected */
    onSelect: (nextSelected: string[]) => void;
} & Pick<DropdownMenuTriggerProps, 'size' | 'fullWidth' | 'label' | 'variant' | 'selected' | 'containTriggerLabel'>;

/**
 * A dropdown menu that displays a set of actions for the user
 */
const DropdownMenu = ({
    label,
    items,
    selected,
    onSelect,

    searchable = false,
    disableMultipleSelections = false,
    variant = 'truncated-pill',
    dynamicTriggerWidth = variant === 'tags',
    containTriggerLabel,

    /** styles */
    fullWidth,
    size = 'sm',
}: DropdownMenuProps) => {
    const [open, setOpen] = useState(false);
    const [triggerDisabled, setTriggerDisabled] = useState(false);
    const triggerRef = useRef<HTMLButtonElement>(null);
    const triggerWidth = useDynamicTriggerWidth(triggerRef, dynamicTriggerWidth);

    const internalItems = hasRawValues(items)
        ? (items.map(value => ({
              value,
              label: value,
              disabled: false,
          })) as MenuItem[])
        : items;

    const clearable = !disableMultipleSelections && selected.length > 0;
    const {
        filteredItems,
        setSearchValue,
        handleClearSearchValue,
        getSelectAllProps,
        getSearchInputProps,
        getItemProps,
    } = useDropdownSearch({
        items: internalItems,
        searchable,
        clearable,
    });

    const handleOnOpenChange = (nextOpen: boolean) => {
        handleClearSearchValue();
        setOpen(nextOpen);
    };

    const handleClear = () => {
        handleClearSearchValue();
        onSelect([]);
    };

    const isItemSelected = (item: MenuItem) => selected.includes(item.value);

    const handleItemSelected = (item: MenuItem) => {
        const isSelected = isItemSelected(item);
        if (disableMultipleSelections) {
            const nextState = isSelected ? [] : [item.value];
            onSelect(nextState);
        } else {
            const nextState = isSelected ? selected.filter(value => value !== item.value) : [...selected, item.value];
            onSelect(nextState);
        }
    };

    const handleSelectAll = () => {
        onSelect(filteredItems.map(item => item.value));
        setOpen(false);
    };

    const onRemoveTag = (value: string) => {
        const nextSelected = selected.filter(val => val !== value);
        onSelect(nextSelected);
    };

    const styles = multiSelectStyles({ size });

    return (
        <RadixDropdownMenu.Root onOpenChange={handleOnOpenChange} open={open} modal={false}>
            <RadixDropdownMenu.Trigger asChild disabled={triggerDisabled} ref={triggerRef} tabIndex={0}>
                <DropdownMenuTrigger
                    variant={variant}
                    label={label}
                    size={size}
                    containTriggerLabel={containTriggerLabel}
                    fullWidth={fullWidth}
                    selected={selected}
                    internalItems={internalItems}
                    setTriggerDisabled={setTriggerDisabled}
                    onRemoveTag={onRemoveTag}
                />
            </RadixDropdownMenu.Trigger>
            <RadixDropdownMenu.Portal>
                <RadixDropdownMenu.Content sideOffset={5} align="start" style={{ width: triggerWidth }}>
                    <div className={styles.base()}>
                        {searchable && (
                            <FlexBox
                                justifyContent="between"
                                alignItems="center"
                                borderBottom={1}
                                borderColor="divider"
                                px={3}
                            >
                                <input
                                    placeholder="Filter..."
                                    onInput={e => {
                                        setSearchValue(e.currentTarget.value);
                                        e.stopPropagation();
                                    }}
                                    autoFocus
                                    className={styles.searchInput()}
                                    {...getSearchInputProps()}
                                />
                                <Button
                                    variant="link"
                                    borderRadius="sm"
                                    size="xs"
                                    className={styles.selectAll()}
                                    onClick={handleSelectAll}
                                    {...getSelectAllProps()}
                                >
                                    Select All
                                </Button>
                            </FlexBox>
                        )}
                        <ScrollArea h={200} thumb="sm" className={styles.scrollContainer()}>
                            <div className={styles.content()}>
                                {clearable && (
                                    <RadixDropdownMenu.CheckboxItem
                                        checked={items.length === selected.length}
                                        onCheckedChange={handleClear}
                                        className={styles.item({
                                            size,
                                        })}
                                        {...getItemProps({ isClearItem: true })}
                                    >
                                        Clear
                                    </RadixDropdownMenu.CheckboxItem>
                                )}
                                {filteredItems?.map((item, idx) => (
                                    <div key={item.value} className={styles.itemWrapper()} data-dropdown-item>
                                        <RadixDropdownMenu.CheckboxItem
                                            {...getItemProps({ isFirstItem: idx === 0 })}
                                            checked={isItemSelected(item)}
                                            onCheckedChange={() => handleItemSelected(item)}
                                            className={styles.item({
                                                size,
                                            })}
                                        >
                                            <ItemLabel {...item} />
                                            <RadixDropdownMenu.ItemIndicator>
                                                <CheckIcon className={styles.indicator()} />
                                            </RadixDropdownMenu.ItemIndicator>
                                        </RadixDropdownMenu.CheckboxItem>
                                    </div>
                                ))}
                            </div>
                        </ScrollArea>
                    </div>
                </RadixDropdownMenu.Content>
            </RadixDropdownMenu.Portal>
        </RadixDropdownMenu.Root>
    );
};

const ItemLabel = ({ label, secondaryLabel, avatar }: MenuItem) => {
    if (avatar || secondaryLabel)
        return (
            <FlexBox alignItems="center" gap="xs">
                {avatar && <Avatar size={12} src={avatar} />}
                <Text as="span" weight={secondaryLabel ? 'medium' : 'regular'}>
                    {label}
                </Text>
                {secondaryLabel && (
                    <Text as="span" color="muted" size="xxs">
                        {secondaryLabel}
                    </Text>
                )}
            </FlexBox>
        );
    return label;
};

export type { DropdownMenuProps };
export { DropdownMenu };
