import { StringUnion } from '@blockworks/platform/typescript';

import { cn } from '../../style-system';

type PriceProps = {
    price?: number;
    className?: string;
    currency?: StringUnion<'USD'>;
    maximumFractionDigits?: number;
};

const Price = ({ price, className, currency, maximumFractionDigits }: PriceProps) => {
    const isMaxFactionDigitsUndefined = typeof maximumFractionDigits !== 'undefined';
    const priceFormatter = Intl.NumberFormat('en', {
        style: 'currency',
        currency: currency ?? 'USD',
        minimumFractionDigits: isMaxFactionDigitsUndefined ? maximumFractionDigits : 2,
        maximumFractionDigits: isMaxFactionDigitsUndefined ? maximumFractionDigits : 2,
    });

    if (price === null) {
        return null;
    }

    return <span className={cn(className)}>{priceFormatter.format(price || 0)}</span>;
};

export type { PriceProps };
export { Price };
