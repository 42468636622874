import * as React from 'react';

import { ResearchHit } from '@blockworks/platform/api/algolia';
import { LightningIcon } from '@blockworks/ui';
import { Badge, FlexBox, List, Text } from '@blockworks/ui/components';
import { DateFormat } from '@blockworks/ui/date-time';

import { AppRoutes } from '@/services/auth/auth.routes';

import { SearchHitProps } from '../utils/search.types';

import { HitItemImage } from './item-components/hit-item-image';
import { ResultHitItemRow } from './item-components/result-hit-item-row';
import { TruncatedTitleText } from './item-components/truncated-title-text';

export const ResearchItem = (props: SearchHitProps<ResearchHit>) => {
    const { onSelect, ...hit } = props;
    const isFlashnote = hit.kind === 'flashnote';
    let href = '';

    if (hit.kind === 'flashnote') {
        href = AppRoutes.FLASHNOTES_SLUG.pathname(hit.slug);
    } else if (hit.kind === 'article') {
        href = AppRoutes.RESEARCH_SLUG.pathname(hit.slug);
    }

    return (
        <ResultHitItemRow id={hit.objectID} href={href} onClick={() => onSelect?.(hit)}>
            <List.Col size="content">
                <HitItemImage alt={hit.title!} src={hit.featuredImageUrl} />
            </List.Col>
            <List.Col size="content" grow>
                <FlexBox h="full" alignItems="start" col>
                    <TruncatedTitleText title={hit.title} />
                    <Text size="xs" color="deselect" lines={1}>
                        <DateFormat date={hit.publishedAt} />
                    </Text>
                </FlexBox>
            </List.Col>
            <List.Col size="content">
                <FlexBox gap="xs">
                    {isFlashnote && (
                        <Badge size="xs" intent="primary">
                            <FlexBox alignItems="center" gap="xs">
                                <LightningIcon size={0.5} color="interactive" />
                                <Text size="xxs" color="interactive">
                                    Flashnote
                                </Text>
                            </FlexBox>
                        </Badge>
                    )}
                    <FlexBox gap="xs" display={{ initial: 'hidden', sm: 'flex' }}>
                        {hit.tags
                            ?.filter(t => t.name.indexOf(':') === -1)
                            .slice(0, 4)
                            .map(tag => <Badge key={tag.name}>{tag.name}</Badge>)}
                    </FlexBox>
                </FlexBox>
            </List.Col>
        </ResultHitItemRow>
    );
};
