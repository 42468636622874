import { forwardRef, PropsWithChildren } from 'react';

import { TVStyleProps } from '../../style-system';

import { badgeStyles } from './badge.styles';

type BadgeProps = PropsWithChildren<TVStyleProps<typeof badgeStyles>>;

const Badge = forwardRef<HTMLDivElement, BadgeProps>(function Badge(
    { children, size = 'xs', variant = 'fill', intent = 'neutral', ...rest },
    ref,
) {
    return (
        <div
            className={badgeStyles({
                ...rest,
                size,
                variant,
                intent,
            })}
            ref={ref}
        >
            {children}
        </div>
    );
});

export type { BadgeProps };
export { Badge };
