import React from 'react';

interface SectionResultsContextType {
    totalCount: number;
    setTotalCount: (count: number) => void;
}

export const SectionResultsContext = React.createContext<SectionResultsContextType>({
    totalCount: 0,
    setTotalCount: () => {},
});

export const useSectionResultsContext = () => React.useContext(SectionResultsContext);
