import { PropsWithChildren } from 'react';

import { ArrowRightIcon, Divider, FlexBox, IconButton, Text } from '@blockworks/ui';

interface ResultsSectionHeaderProps extends PropsWithChildren {
    title: string;
    totalCount?: number;
}

export const ResultsSectionHeader = ({ title, totalCount, children }: ResultsSectionHeaderProps) => {
    const showTotalCount = typeof totalCount !== 'undefined';

    return (
        <FlexBox w="full" justifyContent={'between'} bgColor={'base'} alignItems="center" px={3} pr={4} py={1} gap="md">
            <FlexBox gap="sm">
                <Text weight="bold" size="sm">
                    {title}
                </Text>
                {showTotalCount && (
                    <Text size="xs" color="deselect">
                        {`(${totalCount})`}
                    </Text>
                )}
            </FlexBox>
            <FlexBox flexGrow={1}>
                <Divider orientation="horizontal" />
            </FlexBox>
            {children}
        </FlexBox>
    );
};

const ExpandCategoryButton = ({ onSelect }: { onSelect: () => void }) => (
    <IconButton icon={ArrowRightIcon} aria-label="Select Category" size="md" variant="ghost" onClick={onSelect} />
);

ResultsSectionHeader.ExpandCategoryButton = ExpandCategoryButton;
