import { FlexBox, MagnifyingGlassIcon, Text } from '@blockworks/ui';
import { Bit } from '@blockworks/ui/bit';

interface MobileSearchInputProps {
    handleInputClick: () => void;
}

export const MobileSearchInput = ({ handleInputClick }: MobileSearchInputProps) => (
    <Bit.div
        display={{ initial: 'block', md: 'hidden' }}
        mx={1}
        border={1}
        borderRadius="full"
        p={1}
        onClick={handleInputClick}
    >
        <FlexBox gap="sm" alignItems="center">
            <MagnifyingGlassIcon size={1} color="deselect" />
            <Text size="xs" color="deselect">
                Search
            </Text>
        </FlexBox>
    </Bit.div>
);
