import { forwardRef, LegacyRef } from 'react';
import * as styles from '../icon.styles';
import type { IconProps } from '../icon.model';
const LightningIcon = forwardRef(
    ({ title, titleId, color, borderRadius, ...props }: IconProps, ref?: LegacyRef<SVGSVGElement>) => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            role="img"
            aria-hidden="true"
            className={styles.icon({
                ...props,
                borderRadius,
                color,
            })}
            ref={ref}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                fill="currentColor"
                d="M13.992 2.124a1 1 0 0 0-1.76-.764l-8.93 10.715c-.154.185-.31.373-.424.538-.108.155-.304.462-.31.864a1.5 1.5 0 0 0 .557 1.19c.313.253.674.298.863.315.2.018.443.018.684.018h6.195l-.86 6.876a1 1 0 0 0 1.761.764l8.93-10.715c.154-.185.31-.373.424-.538.108-.155.304-.462.31-.864a1.5 1.5 0 0 0-.557-1.19c-.313-.253-.674-.298-.863-.315C19.812 9 19.57 9 19.328 9h-6.195z"
            />
        </svg>
    ),
);
LightningIcon.displayName = 'LightningIcon';
export default LightningIcon;
