import { Text, TextProps } from '../text';

type PriceChangeTextProps = TextProps & {
    value: number;
    prefix?: string;
    suffix?: string;
    useColorCode?: boolean;
};

const PriceChangeText = ({ suffix, value, ...rest }: PriceChangeTextProps) => {
    const isPositive = Number(value ?? 0) >= 0;

    return (
        <Text color={isPositive ? 'success' : 'danger'} {...rest}>
            {isPositive && '+'}
            {value?.toFixed(2)}
            {suffix ?? '%'}
        </Text>
    );
};

export type { PriceChangeTextProps };
export { PriceChangeText };
