import { Fragment } from 'react/jsx-runtime';

import { FlexBox, type FlexBoxProps } from '../../flex-box';
import { ChevronDownIcon } from '../../icon/icons';
import { Text } from '../../text';
import { DropdownMenuVariants, MenuItem } from '../dropdown-menu.model';

import { iconStyles, TriggerStyleProps } from './dropdown-trigger.styles';

type TriggerContentProps = {
    /** The label (or name of the menu) displayed within the menu trigger.  */
    label: string;
    /** Controlled state for currently-selected options */
    selected: string[];
    /** Force the dropdown trigger to fill `100%` width of its parent container. */
    fullWidth?: TriggerStyleProps['fullWidth'];
    /** Wraps the trigger label in a flex container. */
    containTriggerLabel?: boolean;
    /** Style variants for `DropdownMenu`. */
    variant?: DropdownMenuVariants;
    internalItems?: MenuItem[];
};

const TriggerContent = ({ variant, label, containTriggerLabel, selected, internalItems }: TriggerContentProps) => {
    const labelText = (() => {
        const firstSelected = internalItems?.find(({ value }: MenuItem) => value === selected[0]);
        const base = firstSelected?.textLabel ? firstSelected.textLabel : String(firstSelected?.label ?? '');
        if (selected.length === 0) return null;
        if (selected.length === 1) return base;
        return `${base} + ${selected.length - 1} More`;
    })();

    if (variant === 'tags') {
        return (
            <FlexBox gap="sm" center>
                <Text size="xs">{label}</Text>
                <ChevronDownIcon className={iconStyles()} />
            </FlexBox>
        );
    }

    const Wrapper = containTriggerLabel
        ? { Component: FlexBox, props: { gap: 'sm', center: true } satisfies FlexBoxProps }
        : { Component: Fragment, props: {} };

    return (
        <>
            <Wrapper.Component {...Wrapper.props}>
                {label && <div>{label}</div>}
                <div className="text-content truncate font-bold">{labelText}</div>
            </Wrapper.Component>
            <ChevronDownIcon className={iconStyles()} />
        </>
    );
};

export type { TriggerContentProps };
export { TriggerContent };
