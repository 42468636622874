import React, { useEffect, useRef } from 'react';

const RESULTS_CLASS = '.search-results';
const RECENT_SEARCHES_CLASS = '.recent-searches';
const HITS_QUERY = `${RESULTS_CLASS} li`;
const RECENT_SEARCHES_QUERY = `${RECENT_SEARCHES_CLASS} li`;

const ARROW_DOWN = 'ArrowDown';
const ARROW_UP = 'ArrowUp';
const ENTER = 'Enter';

interface UseSearchHitsNavigationProps {
    query: string;
    focusedIndex: number | undefined;
    onFocusedIndexChange: (index: number | undefined) => void;
    onFocusedHitIdChange: (hitId: string | undefined) => void;
}

// For Algolia instantsearch search results dropdown navigation
export const useSearchHitsNavigation = ({
    query,
    focusedIndex,
    onFocusedIndexChange,
    onFocusedHitIdChange,
}: UseSearchHitsNavigationProps) => {
    const focusedIndexRef = useRef<HTMLDivElement>();

    useEffect(() => {
        const hits = document.querySelectorAll(HITS_QUERY);
        const recentSearches = document.querySelectorAll(RECENT_SEARCHES_QUERY);
        const allItems = [...recentSearches, ...hits];
        if (allItems?.length && typeof focusedIndex !== 'undefined') {
            for (const item of allItems) {
                item.ariaSelected = 'false';
            }
            // Find index by wrapping around the length of hits
            const itemIndex = ((focusedIndex % allItems.length) + allItems.length) % allItems.length;
            const item = allItems[itemIndex] as HTMLDivElement;
            if (item) {
                onFocusedHitIdChange(item.id);
                item.ariaSelected = 'true';
                item.scrollIntoView({ block: 'nearest' });
                focusedIndexRef.current = item;
            } else {
                onFocusedHitIdChange(undefined);
            }
        }
    }, [focusedIndex, query, onFocusedHitIdChange]);

    const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if ([ARROW_DOWN, ARROW_UP, ENTER].includes(e.key)) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (e.key === ARROW_DOWN) {
            onFocusedIndexChange((focusedIndex ?? -1) + 1);
        } else if (e.key === ARROW_UP) {
            onFocusedIndexChange((focusedIndex ?? 0) - 1);
        } else if (e.key === ENTER && focusedIndex !== null) {
            if (focusedIndexRef.current) {
                focusedIndexRef.current.click();
            }
        }
    };

    return { focusedIndexRef, handleInputKeyDown, onFocusedIndexChange };
};
