import { useState } from 'react';

import { useIsomorphicLayoutEffect } from '@blockworks/platform/hooks';

const useDynamicTriggerWidth = (triggerRef: React.RefObject<HTMLButtonElement>, shouldRun?: boolean) => {
    const [triggerWidth, setTriggerWidth] = useState<number | string>('auto');

    useIsomorphicLayoutEffect(() => {
        if (shouldRun) {
            const updateTriggerWidth = () => {
                if (triggerRef.current) {
                    const { width } = (triggerRef.current as HTMLElement).getBoundingClientRect();
                    setTriggerWidth(width);
                }
            };

            updateTriggerWidth();

            window.addEventListener('resize', updateTriggerWidth);

            return () => {
                window.removeEventListener('resize', updateTriggerWidth);
            };
        } else {
            setTriggerWidth('auto');
        }
        return () => {};
    }, [shouldRun]);

    return triggerWidth;
};

export { useDynamicTriggerWidth };
