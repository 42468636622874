import { ComponentProps, ElementType } from 'react';
import * as RadixTabs from '@radix-ui/react-tabs';

import { TVStylePropsWithoutClassName } from '../../style-system';
import { Text } from '../text';

import { tabStyles } from './tabs.styles';

export interface Tab<T extends string> {
    id: T;
    title: string;
    counter?: string | number;
    component: ElementType;
}
export interface TabProps<T extends string>
    extends TVStylePropsWithoutClassName<typeof tabStyles>,
        Omit<ComponentProps<typeof RadixTabs.Root>, 'value' | 'defaultValue' | 'onValueChange'> {
    label: string;
    tabs: Tab<T>[];
    value?: T;
    defaultValue?: T;
    colorVariant?: 'primary';
    tabPlacement?: 'top' | 'bottom';
    onValueChange?: (value: T) => void;
}

export const Tabs = <K extends string>({
    orientation = 'horizontal',
    label,
    tabs,
    value,
    defaultValue,
    colorVariant,
    tabPlacement = 'bottom',
    onValueChange,
    className,
    ...rest
}: TabProps<K>) => {
    const { base, list, tab } = tabStyles({ tabPlacement, colorVariant });
    const renderTabs = () =>
        tabs.map(({ id, component: Component }) => (
            <RadixTabs.Content key={`tab-content-${id}`} value={id}>
                <Component />
            </RadixTabs.Content>
        ));

    return (
        <RadixTabs.Root
            className={base({ className })}
            orientation={orientation}
            value={value}
            defaultValue={defaultValue ?? tabs?.[0]?.id}
            onValueChange={nextVal => onValueChange?.(nextVal as K)}
            {...rest}
        >
            {tabPlacement === 'top' && renderTabs()}
            <RadixTabs.List className={list()} aria-label={label}>
                {tabs.map(({ id, title, counter }) => {
                    return (
                        <RadixTabs.Trigger key={`tab-trigger-${id}`} className={tab()} value={id}>
                            <Text as="span">{title}</Text>
                            {counter && (
                                <Text as="span" color="base">
                                    {counter}
                                </Text>
                            )}
                        </RadixTabs.Trigger>
                    );
                })}
            </RadixTabs.List>
            {tabPlacement === 'bottom' && renderTabs()}
        </RadixTabs.Root>
    );
};
