import { tVariants } from '../../style-system';
import { factoryStyleProps } from '../../style-system/factory/style-props';

const linkStyles = tVariants(
    {
        base: [
            'inline-flex items-center gap-2',
            'cursor-pointer focus-visible:outline-primary focus-visible:outline-1 focus-visible:outline-offset-4',
        ],
        slots: {
            icon: 'w-4 h-4 aspect-square pointer-events-auto',
        },
        defaultVariants: {
            variant: 'default',
            intent: 'neutral',
            size: 'xs',
        },
        variants: {
            variant: {
                default: 'bg-transparent',
                underline: 'bg-transparent hover:underline',
                fill: [
                    'rounded-full',
                    'text-content-deselected bg-surface-muted',
                    'hover:bg-surface-selected hover:text-content-selected',
                ].join(' '),
            },
            intent: {
                neutral: 'text-content hover:text-content-selected',
                primary: 'text-primary hover:text-primary-highlight',
            },
            size: {
                xxs: 'text-xxs',
                xs: {
                    base: 'text-xs gap-1.5',
                    icon: 'w-3.5 h-3.5',
                },
                '2sm': 'text-xs',
                sm: 'text-sm',
                md: 'text-md',
            },
            disabled: {
                true: 'pointer-events-none cursor-default',
            },
            cursor: {
                default: 'cursor-default',
            },
            ...factoryStyleProps.typography,
            ...factoryStyleProps.__atoms.space.margin,
            w: factoryStyleProps.size.w,
            h: factoryStyleProps.size.h,
            justifyContent: factoryStyleProps.layout.justifyContent,
            borderRadius: factoryStyleProps.border.borderRadius,
            overflow: factoryStyleProps.layout.overflow,
            display: factoryStyleProps.layout.display,
        },
        compoundVariants: [
            {
                variant: 'default',
                intent: 'neutral',
                className: 'text-content-deselected hover:text-content-selected',
            },
            {
                variant: 'underline',
                intent: 'neutral',
                className: 'text-content-deselected hover:text-content-selected hover:underline',
            },
            {
                variant: 'fill',
                size: ['xs', '2sm'],
                className: 'py-1 px-3 h-7',
            },
            {
                variant: 'fill',
                size: ['sm', 'md'],
                className: 'py-2 px-3 h-7',
            },
            {
                variant: 'fill',
                size: '2sm',
                // override height
                className: 'h-8',
            },
            {
                variant: 'fill',
                size: 'sm',
                // override height
                className: 'h-9',
            },
            {
                variant: 'fill',
                size: 'md',
                // override height
                className: 'h-10',
            },
            {
                variant: 'fill',
                intent: 'primary',
                className: 'bg-primary-accent text-white hover:bg-primary-highlight hover:text-white',
            },
        ],
    },
    { responsiveVariants: true },
);

export { linkStyles };
