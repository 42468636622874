import React from 'react';

import { useTrack } from '@blockworks/platform/services/analytics';
import { Button, DropdownMenu, FlexBox } from '@blockworks/ui';

import { AnalyticsEvent } from '@/modules/utils/enums/events-tracking';

import { SEARCH_CATEGORIES } from './utils/search.constants';
import { SearchCategoryType } from './utils/search.types';

interface CategoriesSectionProps {
    selected: SearchCategoryType | undefined;
    onSelect: (value: SearchCategoryType | undefined) => void;
}

export const CategoriesSection = ({ selected, onSelect }: CategoriesSectionProps) => {
    const track = useTrack();
    const selectableCategories = SEARCH_CATEGORIES.filter(c => c.selectable !== false);

    const categoryItems = selectableCategories.map(category => ({
        value: category.value,
        label: category.title,
    }));

    const getIntent = (category: SearchCategoryType) => {
        if (category.value === selected?.value) {
            return 'primary-soft';
        }
        return 'neutral';
    };

    const handleSelect = (category: SearchCategoryType) => {
        if (category === selected) {
            onSelect(undefined);
            return;
        }
        track(AnalyticsEvent.searchCategorySelected, { category: category.value });
        onSelect(category);
    };

    const handleDropdownSelect = ([value]: string[]) => {
        const newCategory = SEARCH_CATEGORIES.find(c => c.value === value);
        if (!newCategory || newCategory === selected) {
            onSelect(undefined);
            return;
        }
        onSelect(newCategory);
    };

    return (
        <>
            <FlexBox gap="sm" px={2} py={4} display={{ initial: 'hidden', sm: 'flex' }}>
                {selectableCategories.map(category => (
                    <FlexBox key={category.title} gap="sm" alignItems="center">
                        <FlexBox col>
                            <Button
                                size="xxs"
                                variant="fill"
                                intent={getIntent(category)}
                                onClick={() => handleSelect(category)}
                            >
                                {category.title}
                            </Button>
                        </FlexBox>
                    </FlexBox>
                ))}
            </FlexBox>

            <FlexBox display={{ initial: 'flex', sm: 'hidden' }} w="full" justifyContent="center" my={2}>
                <DropdownMenu
                    items={categoryItems}
                    label="Category"
                    disableMultipleSelections
                    selected={[selected?.value ?? '']}
                    onSelect={handleDropdownSelect}
                />
            </FlexBox>
        </>
    );
};
