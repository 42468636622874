import React, { PropsWithChildren, useEffect } from 'react';
import { Hit } from '@algolia/client-search';

import { SearchHit } from '@blockworks/platform/api/algolia';
import { Configure, useInfiniteHits } from '@blockworks/platform/libs/react-instantsearch';
import { Button, List, Text } from '@blockworks/ui';

import { useSectionResultsContext } from './utils/hooks/use-section-results-context';
import { SearchCategoryType, SearchHitComponent } from './utils/search.types';
import { addRecentSearch } from './utils/search.utils';

interface ResultHitsProps<T extends SearchHit> extends PropsWithChildren {
    filters: string;
    limit: number;
    sectionTitle: SearchCategoryType['title'];
    hitComponent: SearchHitComponent<T>;
    onHitSelect: () => void;
}

type ResultHitsContextType<T extends SearchHit> = {
    isLastPage: boolean;
    sectionTitle: string;
    items: T[];
    showMore: () => void;
    sendEvent: (event: any, item?: any, action?: string) => void;
};

const ResultHitsContext = React.createContext<ResultHitsContextType<SearchHit> | undefined>({
    isLastPage: false,
    sectionTitle: '',
    items: [],
    showMore: () => {},
    sendEvent: () => {},
});

function useResultsHitsContext<T extends SearchHit>() {
    const context = React.useContext(ResultHitsContext);
    if (!context) {
        throw new Error('useResultsHitsContext must be used within a ResultsHitsProvider');
    }
    return context as ResultHitsContextType<T>;
}

export const ResultHits = <T extends SearchHit = SearchHit>({
    children,
    filters,
    sectionTitle,
    limit,
    onHitSelect,
    hitComponent: HitComponent,
}: ResultHitsProps<T>) => {
    const { results, items, isLastPage, showMore, sendEvent } = useInfiniteHits<T>();
    const { setTotalCount } = useSectionResultsContext();

    useEffect(() => {
        // Total count is only accurate inside this "hits" component with the filters applied
        if (typeof results?.nbHits !== 'undefined') {
            setTotalCount(results.nbHits);
        }

        return () => {
            setTotalCount(0);
        };
    }, [results?.nbHits, setTotalCount]);

    const handleHitSelect = (item: T) => {
        onHitSelect?.();
        if (results?.query) addRecentSearch(results.query);
        // Algolia is finnicky about it's types for these functions
        sendEvent('click', item as Hit<any>, 'Navigated from search results');
    };

    return (
        <ResultHitsContext.Provider value={{ isLastPage, items, sectionTitle, showMore, sendEvent }}>
            <Configure hitsPerPage={limit} filters={filters} />
            <List
                variant="compact-transparent"
                bgColor="base"
                name="search-results"
                data-testid={sectionTitle.toLowerCase()}
            >
                <ListContent HitComponent={HitComponent} onHitSelect={handleHitSelect}>
                    {children}
                </ListContent>
            </List>
        </ResultHitsContext.Provider>
    );
};

interface ListContentProps<T extends SearchHit> extends PropsWithChildren {
    HitComponent: SearchHitComponent<T>;
    onHitSelect?: (item: T) => void;
}

const ListContent = <T extends SearchHit>({ children, HitComponent, onHitSelect }: ListContentProps<T>) => {
    const { sectionTitle, items } = useResultsHitsContext<T>();
    const noResultsText = sectionTitle ? `No ${sectionTitle.toLowerCase()} results found` : 'No results found';
    if (!items?.length) {
        return (
            <List.Row>
                <Text size="xs" color="deselect">
                    {noResultsText}
                </Text>
            </List.Row>
        );
    }

    return (
        <>
            {items?.map((item: T) => (
                <HitComponent key={item.objectID} {...(item as Hit<any>)} onSelect={onHitSelect} />
            ))}
            {children}
        </>
    );
};

const ShowMoreButton = () => {
    const { isLastPage, showMore, sendEvent } = useResultsHitsContext();

    if (isLastPage) {
        return null;
    }

    const handleShowMore = () => {
        showMore();
        sendEvent({
            type: 'click',
            action: 'Show More',
        });
    };

    return (
        <List.Row key={'show-more'} pb={2}>
            <List.Col size="content">
                <Button size="xs" variant="outline" intent="neutral" mt={2} onClick={handleShowMore}>
                    {'Show More'}
                </Button>
            </List.Col>
        </List.Row>
    );
};

ResultHits.ShowMoreButton = ShowMoreButton;
