import { DashboardHit } from '@blockworks/platform/api/algolia';
import { FlexBox, List, Text } from '@blockworks/ui/components';

import { getAssetLogo } from '@/utils/logos';

import { SearchHitProps } from '../utils/search.types';

import { HitItemImage } from './item-components/hit-item-image';
import { ResultHitItemRow } from './item-components/result-hit-item-row';

export const DashboardItem = (props: SearchHitProps<DashboardHit>) => {
    const { onSelect, ...hit } = props;
    const imageSrc = getAssetLogo(hit.tag?.slug ?? '');

    return (
        <ResultHitItemRow id={hit.objectID} href={hit.href} onClick={() => onSelect?.(hit)}>
            <List.Col size="content">
                <HitItemImage alt={hit.tag?.name} src={imageSrc} />
            </List.Col>
            <List.Col size="fit" grow>
                <FlexBox>
                    <Text size="sm">{hit.title}</Text>
                </FlexBox>
            </List.Col>
            {hit.tabName && (
                <List.Col size="content" shrink>
                    <Text size="xs" color="muted">
                        {hit.tabName}
                    </Text>
                </List.Col>
            )}
        </ResultHitItemRow>
    );
};
