import React, { PropsWithChildren } from 'react';

import { ArrowWrapIcon, List, ListRowProps } from '@blockworks/ui';

import { useSearchContext } from '../../utils/hooks/use-search-context';

interface ResultHitItemRowProps extends ListRowProps, PropsWithChildren {}

export const ResultHitItemRow = ({ children, ...hitProps }: ResultHitItemRowProps) => {
    const { focusedHitId } = useSearchContext();
    const isFocused = focusedHitId === hitProps.id;

    return (
        <List.Row w="full" as="li" bgColor="base" justifyContent="between" px={2} py={1} {...hitProps} rounded>
            {children}
            {isFocused && (
                <List.Col shrink>
                    <ArrowWrapIcon size={0.75} />
                </List.Col>
            )}
        </List.Row>
    );
};
