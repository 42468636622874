import { Avatar, FlexBox, Image, MiniLogo, Text } from '@blockworks/ui';

interface HitItemImageProps {
    size?: {
        w: number;
        h: number;
    };
    alt?: string | undefined | null;
    src?: string | undefined | null;
    // use the first letter of the alt prop as the avatar if no src is provided
    useFirstLetter?: boolean;
}

export const HitItemImage = ({ size, alt, src, useFirstLetter }: HitItemImageProps) => {
    if (size && src) {
        return <Image width={size.w} height={size.h} alt={alt ?? ''} src={src} />;
    }

    if (src && alt) {
        return <Avatar size={24} alt={alt} src={src} />;
    }

    return (
        <FlexBox center bgColor={'muted'} borderRadius="full" p={1} className="h-[24px] w-[24px]">
            <DefaultContent alt={alt} useFirstLetter={useFirstLetter} />
        </FlexBox>
    );
};

const DefaultContent = ({ alt, useFirstLetter }: Omit<HitItemImageProps, 'src'>) => {
    if (useFirstLetter && alt) {
        return (
            <Text size="lg" color="deselect" className="text-center">
                {alt.charAt(0).toUpperCase()}
            </Text>
        );
    }

    return <MiniLogo size={18} />;
};
