import { tVariants } from '../../style-system';

const multiSelectStyles = tVariants({
    base: 'flex flex-col w-full min-w-[200px] bg-surface border border-surface-divider rounded-md overflow-hidden shadow-2xl',
    slots: {
        itemWrapper: 'w-full',
        item: 'flex py-1.5 px-3 justify-between items-center gap-1 data-highlighted:outline-0 data-highlighted:bg-surface-muted cursor-pointer',
        indicator: 'leading w-3.5 h-3.5',
        searchInput: 'ring-0 focus:ring-0 focus:border-none px-0 border-none font-sm grow bg-surface text-xs',
        content: 'divide-y divide-surface-muted w-full',
        selectAll: 'font-semibold h-4 px-0 py-0',
        scrollContainer: 'min-w-[200px] w-full overflow-hidden rounded-none',
    },
    variants: {
        size: {
            sm: {
                item: 'text-xs',
            },
            md: {
                item: 'text-sm',
            },
        },
    },
});

export { multiSelectStyles };
