import React, { PropsWithChildren, useEffect } from 'react';

import { useInstantSearch, useSearchBox, UseSearchBoxProps } from '@blockworks/platform/libs/react-instantsearch';
import { useTrack } from '@blockworks/platform/services/analytics';
import { Button, FlexBox, IconButton, Input, Text } from '@blockworks/ui/components';
import { ArrowLeftIcon, MagnifyingGlassIcon } from '@blockworks/ui/icon';

import { AnalyticsEvent } from '@/modules/utils/enums/events-tracking';

import { SearchCategoryType } from '../utils/search.types';
import { getCategoryByShortcut } from '../utils/search.utils';

interface ModalSearchInputProps extends UseSearchBoxProps, PropsWithChildren {
    resultsRef: React.RefObject<HTMLDivElement>;
    hideClearButton?: boolean;
    inputValue: string;
    inputRef: React.RefObject<HTMLInputElement>;
    selectedCategory: SearchCategoryType | undefined;
    onCategoryChange: (category: SearchCategoryType | undefined) => void;
    onInputChange: (value: string) => void;
    resetFocusedHitIndex: () => void;
}

const SearchIcon = ({ children }: PropsWithChildren) => {
    if (children) {
        return children;
    }
    return <MagnifyingGlassIcon size={1.5} color="deselect" />;
};

export const ModalSearchInput = ({
    children,
    resultsRef,
    hideClearButton,
    inputValue,
    inputRef,
    selectedCategory,
    onCategoryChange,
    onInputChange,
    resetFocusedHitIndex,
}: ModalSearchInputProps) => {
    const { query, refine } = useSearchBox();
    const { status } = useInstantSearch();
    const isSearchStalled = status === 'stalled';
    const isClearDisabled = hideClearButton || inputValue?.length === 0 || isSearchStalled;
    const { shortcut, title } = selectedCategory ?? {};
    const shortcutRegex = shortcut ? new RegExp(`^${shortcut}`) : undefined;
    const displayedValue = shortcutRegex ? inputValue?.replace(shortcutRegex, '') : inputValue;
    const placeholder = title ? `Search ${title.toLowerCase()}` : 'Search for assets, articles, proposals and more...';
    const track = useTrack();

    useEffect(() => {
        if (displayedValue !== query) {
            if (displayedValue === '/') return;
            // User just typed a new shortcut if found
            const newCategory = getCategoryByShortcut(displayedValue);
            if (newCategory) {
                onCategoryChange(newCategory);
                track(AnalyticsEvent.searchCategoryTyped, { category: newCategory });
                return;
            }
            refine(displayedValue);
            resetFocusedHitIndex();
            resultsRef.current?.scrollTo({ top: 0 });
        }
    }, [displayedValue, resultsRef, refine, resetFocusedHitIndex, onCategoryChange]); // @dev: do not add `query` as a dependency here

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onInputChange(event.currentTarget?.value);
    };

    // if delete is pressed when there is no input value, set the shortcut to undefined
    const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const isDeleteKey = event.key === 'Backspace' || event.key === 'Delete';
        if (isDeleteKey && selectedCategory && !displayedValue) {
            onCategoryChange(undefined);
            onInputChange('');
        }
    };

    const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (inputRef?.current) {
            inputRef.current.blur();
        }
    };

    const handleFormReset = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        onInputChange('');
        if (inputRef?.current) {
            inputRef.current.focus();
        }
    };

    const handleClearButtonClick = () => {
        onInputChange('');
    };

    return (
        <FlexBox px={3} py={1} bgColor="raised" h="full" borderBottom={1} borderColor="selected" alignItems="center">
            <form
                action=""
                role="search"
                className="flex w-full align-center items-center gap-1"
                noValidate
                onSubmit={handleFormSubmit}
                onReset={handleFormReset}
            >
                <FlexBox flexGrow={1}>
                    <SearchIcon>{children}</SearchIcon>
                </FlexBox>
                <FlexBox flexGrow={1} w="full" alignItems="center">
                    <>
                        {shortcut && (
                            <Text size="sm" color="interactive">
                                {shortcut}
                            </Text>
                        )}
                        <Input
                            ref={inputRef}
                            size={'md'}
                            w="full"
                            data-testid="search-input"
                            variant={'transparent'}
                            noOutline
                            name="search-field"
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            placeholder={placeholder}
                            spellCheck={false}
                            maxLength={200}
                            type="search"
                            value={displayedValue}
                            onKeyDown={handleInputKeyDown}
                            onInput={handleInputChange}
                            autoFocus
                        />
                    </>
                </FlexBox>
                {!isClearDisabled && (
                    <Button
                        size={'xs'}
                        variant="fill"
                        intent="neutral"
                        onClick={handleClearButtonClick}
                        aria-label="Search"
                    >
                        {`Clear`}
                    </Button>
                )}
            </form>
        </FlexBox>
    );
};

const BackIcon = ({ onClick }: { onClick: () => void }) => (
    <IconButton
        aria-label="Back To All Categories"
        icon={ArrowLeftIcon}
        variant="ghost"
        color="deselect"
        size="xs"
        onClick={onClick}
    />
);

ModalSearchInput.BackIcon = BackIcon;
