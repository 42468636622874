import React from 'react';

type SearchContextType = {
    focusedHitId: string | undefined;
};

export const SearchContext = React.createContext<SearchContextType>({
    focusedHitId: undefined,
} as SearchContextType);

export const useSearchContext = () => React.useContext(SearchContext);
