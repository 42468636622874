import React, { PropsWithChildren } from 'react';

import { Index } from '@blockworks/platform/libs/react-instantsearch';
import { ResearchIndexes } from '@blockworks/platform/services/algolia';
import { FlexBox } from '@blockworks/ui/components';

import { SectionResultsContext, useSectionResultsContext } from './utils/hooks/use-section-results-context';
import { ResultsSectionHeader } from './result-section-header';

export const ResultSection = ({ children }: PropsWithChildren) => {
    const [totalCount, setTotalCount] = React.useState(0);

    return (
        <SectionResultsContext.Provider value={{ totalCount, setTotalCount }}>
            <Index indexName={ResearchIndexes.BWRSearch} indexId={ResearchIndexes.BWRSearch}>
                <FlexBox col>{children}</FlexBox>
            </Index>
        </SectionResultsContext.Provider>
    );
};

interface ResultsSectionHeaderProps {
    title: string;
    onSelect: () => void;
}

const Header = ({ title, onSelect }: ResultsSectionHeaderProps) => {
    const { totalCount } = useSectionResultsContext();

    return (
        <ResultsSectionHeader title={title} totalCount={totalCount}>
            <ResultsSectionHeader.ExpandCategoryButton onSelect={onSelect} />
        </ResultsSectionHeader>
    );
};

ResultSection.Header = Header;
