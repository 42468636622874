import { tVariants } from '../../style-system';
import { factoryStyleProps } from '../../style-system/factory/style-props';

const buttonStyles = tVariants(
    {
        base: [
            'inline-flex items-center',
            'focus-visible:outline-primary focus-visible:outline-1 focus-visible:outline-offset-4',
        ],
        defaultVariants: {
            variant: 'fill',
            intent: 'neutral',
        },
        variants: {
            variant: {
                fill: 'border border-transparent',
                outline: 'bg-transparent border',
                ghost: 'bg-transparent border border-transparent',
                link: 'text-sm font-medium border text-content hover:text-content border-transparent bg-transparent active:bg-transparent py-0 px-0 h-7 gap-2 data-hide-spinner',
            },
            /**
             * We don't have a clarified color system to use intent/color and variants optimally.
             * So let's leave these empty and purely manipulate these classes in compoundVariants.
             **/
            intent: {
                neutral: '',
                muted: '',
                primary: '',
                'primary-soft': '',
                danger: '',
            },
            disabled: { true: 'opacity-60 pointer-events-none cursor-not-allowed' },
            size: {
                base: 'text-sm',
                flush: 'py-0 px-0 h-7 gap-2 data-hide-spinner',
                xxs: 'py-1 px-2 text-xs h-5 gap-1',
                xs: 'py-1 px-3 text-xs h-7 gap-2',
                '2sm': 'py-1.5 px-3 text-xs h-8 gap-2',
                sm: 'py-2 px-3 text-sm h-9 gap-2.5',
                md: 'py-3 px-4 text-md h-11 gap-2.5 data-size-md',
            },
            /** @todo remove if not used anywhere */
            control: { true: 'border-0' },
            fit: { true: 'w-full h-full' },
            center: { true: 'items-center justify-center' },
            ...factoryStyleProps.border,
            ...factoryStyleProps.__atoms.space.margin,
            ...factoryStyleProps.color,
            ...factoryStyleProps.layout,
            ...factoryStyleProps.size,
            textDecoration: factoryStyleProps.typography.textDecoration,
        },
        compoundVariants: [
            {
                variant: 'fill',
                intent: 'primary',
                className: [
                    'text-white bg-primary-accent border-transparent',
                    'hover:bg-primary-highlight active:bg-primary-interact disabled:bg-primary-muted',
                    'data-selected:bg-primary-highlight',
                ].join(' '),
            },
            {
                variant: 'fill',
                intent: 'primary-soft',
                className: [
                    'text-primary dark:text-primary-content bg-primary-surface-soft border-transparent',
                    'hover:bg-primary-active active:bg-primary-active disabled:bg-primary-muted',
                    'data-selected:bg-primary-active',
                ].join(' '),
            },
            {
                variant: 'fill',
                intent: 'muted',
                className: [
                    'text-white bg-muted border-transparent',
                    'hover:bg-muted-hover active:bg-muted-hover',
                    'data-selected:bg-muted-hover',
                ].join(' '),
            },
            {
                variant: 'fill',
                intent: 'neutral',
                className: [
                    'text-content-deselected bg-surface-muted border-transparent',
                    'hover:bg-surface-selected hover:text-content-selected active:bg-surface',
                    'data-selected:text-content-selected data-selected:bg-surface-selected',
                ].join(' '),
            },
            {
                variant: 'fill',
                intent: 'danger',
                className: [
                    'text-white bg-danger-accent',
                    'hover:bg-danger',
                    'active:bg-danger-highlight',
                    'data-selected:bg-danger-highlight',
                    'disabled:bg-danger-muted disabled:text-danger',
                ].join(' '),
            },
            {
                variant: 'link',
                intent: 'primary',
                className:
                    'text-primary hover:text-primary-accent active:text-primary-highlight data-selected:text-primary-highlight',
            },
            {
                variant: 'link',
                intent: 'muted',
                className:
                    'text-muted hover:text-muted-hover active:text-muted-highlight data-selected:text-muted-highlight',
            },
            {
                variant: 'link',
                intent: 'neutral',
                className: 'text-content-muted hover:text-content active:text-content data-selected:text-content',
            },
            {
                variant: 'link',
                intent: 'danger',
                className: [
                    'text-danger bg-transparent',
                    'hover:text-danger-accent',
                    'active:text-danger-content',
                    'data-selected:text-danger-content',
                ].join(' '),
            },
            {
                /** @todo should ghost use bg-current-bg like iconbutton */
                variant: 'ghost',
                intent: 'primary',
                className: [
                    'text-primary',
                    'hover:text-white hover:bg-primary-highlight',
                    'active:text-white active:bg-primary-interact',
                    'data-selected:text-white data-selected:bg-primary-highlight',
                ].join(' '),
            },
            {
                variant: 'ghost',
                intent: 'muted',
                className: [
                    'text-muted',
                    'hover:text-muted-hover',
                    'active:text-muted-highlight',
                    'data-selected:text-muted-highlight',
                ].join(' '),
            },
            {
                variant: 'ghost',
                intent: 'neutral',
                className: [
                    'text-content-deselected bg-transparent',
                    'hover:text-content hover:bg-surface-muted',
                    'active:text-content active:bg-surface-muted ',
                    'data-selected:text-content data-selected:bg-surface-muted',
                ].join(' '),
            },
            {
                variant: 'ghost',
                intent: 'danger',
                className: [
                    'text-danger bg-transparent',
                    'hover:text-danger-highlight hover:bg-danger-muted',
                    'active:text-danger-highlight active:bg-danger-active ',
                    'data-selected:text-danger-highlight data-selected:bg-danger-active',
                    'disabled:bg-danger-muted disabled:text-danger',
                ].join(' '),
            },
            {
                variant: 'outline',
                intent: 'primary',
                className: [
                    'text-primary border-primary',
                    'hover:text-primary-highlight hover:border-primary-highlight',
                    'active:text-primary-interact hover:border-primary-interact',
                    'data-selected:text-primary-highlight data-selected:border-primary-highlight',
                ].join(' '),
            },
            {
                variant: 'outline',
                intent: 'muted',
                className: [
                    'text-muted border-muted',
                    'hover:text-muted-hover hover:border-muted-hover',
                    'active:text-muted-highlight',
                    'data-selected:text-content-selected data-selected:bg-surface-selected',
                    'disabled:border-muted-highlight disabled:text-muted-highlight',
                ].join(' '),
            },
            {
                variant: 'outline',
                intent: 'neutral',
                className: [
                    'text-content-deselected border-surface-selected',
                    'hover:text-content-selected hover:bg-surface-selected active:bg-surface',
                    'data-selected:text-content-selected data-selected:bg-surface-selected',
                ].join(' '),
            },
            {
                variant: 'outline',
                intent: 'danger',
                className: [
                    'text-danger border-danger',
                    'hover:text-danger-accent hover:border-danger-accent',
                    'active:border-danger-accent active:text-danger-accent',
                    'data-selected:text-danger-content data-selected:border-danger-content data-selected:bg-danger-active',
                    'disabled:bg-danger-active disabled:text-danger-accent disabled:border-danger-accent',
                ].join(' '),
            },
        ],
    },
    {
        responsiveVariants: true,
    },
);

export { buttonStyles };
