import { Text } from '@blockworks/ui';
import { Bit } from '@blockworks/ui/bit';

export const TruncatedTitleText = ({ title }: { title: string | undefined | null }) => (
    <Bit.div w={{ initial: 200, xs: 300, md: 400 }} display="inline">
        <Text size="sm" truncate>
            {title ?? ''}
        </Text>
    </Bit.div>
);
