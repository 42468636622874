import { forwardRef, LegacyRef } from 'react';
import * as styles from '../icon.styles';
import type { IconProps } from '../icon.model';
const TooltipCircleIcon = forwardRef(
    ({ title, titleId, color, borderRadius, ...props }: IconProps, ref?: LegacyRef<SVGSVGElement>) => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 16 16"
            width={props.width}
            height={props.height}
            role="img"
            aria-hidden="true"
            className={styles.icon({
                ...props,
                borderRadius,
                color,
            })}
            ref={ref}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <g clipPath="url(#tooltip-circle_svg__a)">
                <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.333}
                    d="M6.06 6a2 2 0 0 1 3.887.666c0 1.334-2 2-2 2M8 11.333h.007M14.667 8A6.667 6.667 0 1 1 1.334 8a6.667 6.667 0 0 1 13.333 0"
                />
            </g>
            <defs>
                <clipPath id="tooltip-circle_svg__a">
                    <path fill="currentColor" d="M0 0h16v16H0z" />
                </clipPath>
            </defs>
        </svg>
    ),
);
TooltipCircleIcon.displayName = 'TooltipCircleIcon';
export default TooltipCircleIcon;
