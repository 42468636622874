import { SEARCH_CATEGORIES } from './search.constants';
import { SearchCategoryValues } from './search.enums';
import { RecentSearchItemType, SearchCategoryType } from './search.types';

export const addRecentSearch = (searchTerm: string) => {
    const recentSearches = getRecentSearches();
    const duplicate = recentSearches.find(recentSearch => recentSearch.searchTerm === searchTerm);
    let newRecentSearches: RecentSearchItemType[] = recentSearches;
    if (duplicate) {
        duplicate.isoDate = new Date().toISOString();
        newRecentSearches = recentSearches.map(recentSearch =>
            recentSearch.searchTerm === duplicate.searchTerm ? duplicate : recentSearch,
        );
    } else {
        newRecentSearches.push({ searchTerm, isoDate: new Date().toISOString() });
    }
    localStorage.setItem('recent-searches', JSON.stringify(newRecentSearches));
};

export const getRecentSearches = (): RecentSearchItemType[] => {
    const recentSearches = localStorage.getItem('recent-searches');
    if (recentSearches === null) {
        return [];
    }
    const parsedRecentSearches = JSON.parse(recentSearches);
    return parsedRecentSearches.sort(
        (a: RecentSearchItemType, b: RecentSearchItemType) =>
            new Date(b.isoDate).getTime() - new Date(a.isoDate).getTime(),
    );
};

export const deleteRecentSearch = (searchTerm: string) => {
    const recentSearches = getRecentSearches();
    const newRecentSearches = recentSearches.filter(recentSearch => recentSearch.searchTerm !== searchTerm);
    localStorage.setItem('recent-searches', JSON.stringify(newRecentSearches));
};

export const clearRecentSearches = () => {
    localStorage.removeItem('recent-searches');
};

export const getCategoryByValue = (value: SearchCategoryValues) => {
    return SEARCH_CATEGORIES.find(cat => cat.value === value) as SearchCategoryType;
};

export const getCategoryByShortcut = (shortcut: string) => {
    return SEARCH_CATEGORIES.find(cat => cat.shortcut === shortcut) as SearchCategoryType;
};
