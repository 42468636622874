import { forwardRef } from 'react';
import * as RadixScrollArea from '@radix-ui/react-scroll-area';

import { TVStyleProps } from '../../style-system';

import { scrollAreaStyles } from './scroll-area.styles';

interface ScrollAreaProps extends React.PropsWithChildren, TVStyleProps<typeof scrollAreaStyles, true> {
    h: number | string;
    id?: string;
    viewport?: React.ForwardRefExoticComponent<RadixScrollArea.ScrollAreaProps & React.RefAttributes<HTMLDivElement>>;
}

const ScrollArea = forwardRef<HTMLDivElement, ScrollAreaProps>(function ScrollArea(
    {
        children,
        id,
        viewport: Viewport,
        className,
        thumb = 'md',
        h,
        px,
        py,
        pr,
        pl,
        w = 'full',
        fitContainer,
        maxW,
        transparent,
    },
    ref,
) {
    const styles = scrollAreaStyles();

    const renderViewport = () => {
        const base = (
            <RadixScrollArea.Viewport
                id={id}
                ref={ref}
                className={styles.base({ fitContainer, w, px, py, pr, pl, maxW })}
            >
                {children}
            </RadixScrollArea.Viewport>
        );
        if (Viewport) {
            <Viewport asChild>{base}</Viewport>;
        }
        return base;
    };

    return (
        <RadixScrollArea.Root
            className={styles.wrapper({ fitContainer, thumb, transparent, className })}
            style={
                {
                    '--scroll-width': typeof w === 'number' ? `${w}px` : w,
                    '--scroll-height': typeof h === 'number' ? `${h}px` : h,
                } as {}
            }
            type="auto"
        >
            {renderViewport()}
            <RadixScrollArea.Scrollbar className={styles.scrollbar()} orientation="vertical">
                <RadixScrollArea.Thumb className={styles.thumb()} />
            </RadixScrollArea.Scrollbar>
            <RadixScrollArea.Scrollbar className={styles.scrollbar()} orientation="horizontal">
                <RadixScrollArea.Thumb className={styles.thumb()} />
            </RadixScrollArea.Scrollbar>
        </RadixScrollArea.Root>
    );
});

export type { ScrollAreaProps };
export { ScrollArea };
